import { getData } from './request.js'

/**
 * 星视频--四表合一数据 接口
 */
export function IntegrationExport(data) {
  return getData({
    url: 'StarTask/Integration/export',
    method: 'post',
    data: data
  })
}

export function RealTimeExport(data) {
  return getData({
    url: 'StarTask/realtime/export',
    method: 'post',
    data: data
  })
}

export function RealTime2Export(data) {
  return getData({
    url: 'StarTask/realtime2/export',
    method: 'post',
    data: data
  })
}

/**
 * 星任务（视频）--查询订单的投后数据
 */
export function investmentData(data) {
  return getData({
    url: 'StarTask/investmentData/export',
    method: 'post',
    data: data
  })
}

/**
 * 星任务（视频）--订单分天数据接口
 */
export function investmentDataDaily(data) {
  return getData({
    url: 'StarTask/investmentDataDaily/export',
    method: 'post',
    data: data
  })
}
